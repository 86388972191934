body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1b;
  color:white;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  color: white;
  font-size: 50px;
  line-height: 0.98em;
  font-weight: 600;
  letter-spacing: -.005em;
  text-shadow: 0px 0px 10px rgba(0,0,0, 0.30);
}

h2 {
  color: white;
  font-size: 40px;
  line-height: 0.98em;
  font-weight: 600;
  letter-spacing: -.005em;
  text-shadow: 0px 0px 10px rgba(0,0,0, 0.30);
}

h4 {
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-shadow: 1px 1px 1px rgba(0,0,0, 0.16);
}

h5 {
  color: rgb(152,152,152);
  font-weight: 400;
  line-height: 1.5em;
  font-size: 18px;
  text-shadow: 1px 1px 1px rgba(0,0,0, 0.16);
}

h6 {
  color: rgb(76,76,76);
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 0.98em;
  font-size: 18px;
}

p {
  color: rgb(152,152,152);
  font-weight: 400;
  line-height: 1.5em;
  font-size: 18px;
  text-shadow: 1px 1px 1px rgba(0,0,0, 0.16);
}

a {
  color: white;
}

/*
 * Sidebar
 */

.sidebar {
  padding: 0px 20px 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  width: 250px;
}

.sidebar .sidebar-logo {
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-top: 48px;
}

.sidebar .sidebar-logo img {
  width: 50px;
  height: 50px;
  margin: -10px 10px 0px 20px;
}

.sidebar .sidebar-title {
  margin-bottom: 20px;
  padding-left: 20px;
}

.sidebar .sidebar-title h2 {
  background: #0287e3;
  background: linear-gradient(
          230deg
          ,#5df8fe 40%,#e115b4 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-sticky .account {
  position: absolute;
  display: inline-block;
  bottom: 20px;
}

.sidebar-sticky .sidebar-heading {
   margin-bottom: 10px !important;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 400;
  margin: 5px;
  border-radius: 8px;
  color:white;
}

.sidebar .nav-link svg {
  margin-right: 10px;
  margin-top: -2px;
  width: 20px;
  height: 20px;
  color: RGB(10, 132, 255);
}

.sidebar .nav-link.active {
  background-color: RGB(10, 132, 255);
}

.sidebar .nav-link.active svg {
  color: white;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}


.sidebar-heading {
  font-size: 18px;
  color:white;
  line-height: 0.98em;
  font-weight: 600;
  letter-spacing: -.005em;
  text-shadow: 0px 0px 10px rgba(0,0,0, 0.30);
}

/*
 * Content
 */

.main {
  padding: 85px;
  width: calc(100% - 250px);
}

.main h1 {
  margin-bottom: 40px;
}

/*
 * Login
 */

.login {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  margin-top: 100px;
}

.login img {
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
}

.login Button {
  margin-top: 30px;
}

.version {
  bottom: 0px;
  position: fixed;
  width: 100%;
  text-align: right;
  padding: 10px;
}

/*
 * HOME
 */

.icon-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.5rem;
  border-radius: .75rem;
}

.icon-square .active {
  background-color: RGB(10, 132, 255);
}

/*
 * Fixtures
 */



/*
 * Manufacturers
 */

.manufacturers .card .card-text {
  color: white;
}

.manufacturers .card .dropdown {
  float: right;
}

.manufacturers .card .btn-outline-secondary {
  color: white;
}

.manufacturers .card .btn-secondary {
  background-color: transparent;
  border: none;
}

.manufacturers .card .dropdown-toggle::after {
  border: none;
  margin: 0;
}

.manufacturers .card .btn-check:focus+.btn-secondary, .manufacturers .card .btn-secondary:focus {
  box-shadow: none;
  background-color: #343a40 !important;
  color: white !important;
}

.manufacturers .card .btn-secondary:focus svg {
  color: white !important;
}

/*
----
 */

.main .search {
  float: right;
  margin-top: 10px;
  padding: 10px;
  width: 300px;
}

.list .item {
  padding: 10px;
  border-radius: 5px;
  margin:1px;
}

.list .item.disabled {
  opacity: 0.5;
}

.list .item.bg-warning, .main .list .item.bg-warning h4, .main .list .item.bg-warning svg {
  text-shadow: 0px 0px 0px rgb(0 0 0);
}

.list .item .state {
  margin-top: -1px;
  margin-right: 5px;
  float: left;
}

.list .item .btn-secondary {
  background-color: transparent;
  border: none;
  float: right;
}

.list .item .dropdown a {
  cursor: pointer;
}

.list .item .dropdown-toggle::after {
  border: none;
  margin: 0;
}

.list .item .btn-check:focus+.btn-secondary, .main .list .item .btn-secondary:focus {
  box-shadow: none;
  background-color: #343a40 !important;
  color: white !important;
}

.list .item .btn-secondary:focus svg {
  color: white !important;
}

.list .item .comment {
  background-color: #1a1a1b;
  padding:10px;
  line-height: 40px;
  border-radius: 5px;
}

.list .item .comment .more_comment, .list .item .comment .edit_comment {
  float: right;
  color: inherit;
  background-color: transparent;
  padding: 0;
}

.list .item .comment .more_comment:hover, .list .item .comment .edit_comment:hover {
  color: white!important;
  cursor: pointer;
}

Button {
  background-color: RGB(10, 132, 255);
  border:none;
  padding:10px;
}

.loader {
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
  margin-top: 200px;
}

.list-group-item {
  background-color: black;
}

.table {
  font-weight: 300;
}

Input {
  background-color: black;
  border: none;
  padding: 5px;
  border-radius: 5px;
  color:white;
}

.btn-outline-secondary {
  border-color: #1a1a1b;
}

.Modal {
  width: 600px;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #1a1a1b;
  padding: 30px;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  border-radius: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Modal::-webkit-scrollbar {
  display: none;
}

.Modal h2 {
  margin-bottom: 30px;
  margin-right: 40px;
}

.Modal .close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.Modal .close .btn {
  color: white;
}

.Modal .close .btn svg {
  width: 20px;
  height: 20px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 37, 41, 0.8);
}

@media screen and (max-width: 1400px) {
  .main {
    padding: 60px;
  }

  .main h1 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 992px) {
  .main {
    padding: 40px;
  }

  .main .search {
    float: left;
    margin-bottom: 30px;
  }

  .main h1 {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .main {
    padding: 30px 10px;
    width: 100%;
  }

  .main h1 {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
  }

  .Modal {
    width: 100%;
  }
}

.wrapper {
  height: 500px !important;
}

.fixture .btn-group {
  float: right;
}

.fixture .manufacturer {
  margin-bottom: 20px;
  margin-top: -20px;
  display: block;
}

.fixture .list {
  margin-top: 20px;
}

.fixture .leftManufacturer {
  float: right;
}

.fixture .rightManufacturer {
  float: right;
  text-align: right;
  padding-right: 10px;
}

.property .isCustom {
  width: 30px;
  height: 30px;
  float: right;
}

.range {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
}

.range .rangeNumbers {
  background-color: #1a1a1b;
  border-radius: 5px;
  text-align: center;
}

.range .legend {
  padding: 10px;
  border-radius: 5px;
  text-shadow: 0px 0px 3px #000;
}

.range .number {
  padding-left: 20px;
  line-height: 20px;
}

.range .number span {
  font-size: 20px;
  font-weight: 600;
  display: block;
}


.responseFixture {
  margin-top: 20px;
  border-radius: 5px;
  padding: 15px;
  margin-left: 0px;
  background-color: #212529;
}
.responseFoundFixture {
  margin-top: 20px;
  border-radius: 5px;
  padding: 15px;
  margin-left: 0px;
  background-color: #212529;
}

.list a:link {
  text-decoration:none;
}

.list a:hover {
  color: inherit;
}



.float-right {
  float: right;
}

.no-item {
  font-size: 30px;
  text-align: center;
  opacity: 0.5;
}

.no-item svg {
  font-size: 50px;
}