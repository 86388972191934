@import "../../variables.scss";

.sidebar {
  .bullet {
    background-color: RGB(10, 132, 255);
    width: 10px;
    height: 10px;
    display: block;
    float: right;
    margin: 8px;
    border-radius: 5px;
  }

  .nav-link.active .bullet {
    background-color: white;
  }
}

.Menu {
  width: 250px;

  &_mobile {
    display: none;
  }

  &_menu {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sidebar {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    height: 700px;
    width: 100% !important;
    padding: 20px 80px 0 20px !important;
    background-color: #101010;
    transition: transform .3s ease;
    transform: translateY(-100%);

    .sidebar-logo {
      display: none;
    }

    .sidebar-sticky {
      position: relative;
      height: auto;
    }

    .account {
      position: relative !important;
      margin-top: 60px;
    }

    .nav-link {
      font-size: 20px;
      font-weight: 200!important;
    }

    .nav-link.active {
      background-color: transparent !important;
      font-weight: 500 !important;

      svg {
        color: RGB(10, 132, 255) !important;
      }
    }

    .nav-link .bullet {
      display: none;
    }

  }

  .sidebar.open {
    transform: translateY(0);
  }

  .Menu {
    &_menu {
      position: absolute;
      top: 45px;
      right: 30px;
      height: 18px;
      width: 23px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      z-index: 100;

      .top-bar {
        top: 0;
        right: 25%;
        border-radius: 5px;
        position: relative;
        height: 2px;
        width: 100%;
        background-color: #fff;
        transform-origin: right top;
        transition: transform .15s ease-in-out;

        &.open {
          transform: rotate(-45deg);
        }
      }

      .middle-bar {
        position: relative;
        height: 2px;
        width: 100%;
        background-color: #fff;
        transition: opacity .15s ease;

        &.open {
          opacity: 0;
        }
      }

      .bottom-bar {
        bottom: 0;
        right: 25%;
        position: relative;
        height: 2px;
        width: 100%;
        background-color: #fff;
        transform-origin: right bottom;
        transition: transform .15s  ease-in-out;

        &.open {
          transform: rotate(45deg);
        }
      }
    }
  }
}